<template>
  <div class="sellform-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>全部游戏</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>填写商品信息</span>
    </div>
    <div class="box">
      <div class="title">
        <img src="@/assets/images/recreation-icon.png" alt="" />
        <p>选择区服</p>
      </div>
      <div class="content">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>当前游戏为</p>
        </div>
        <div class="operate" @click="gopage">
          <img :src="game.image" alt="" />
          <p>重新选择</p>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>账号信息</p>
        </div>
        <div class="account-info">
          <div class="account">
            <p>游戏账号</p>
            <input type="text" placeholder="请填写游戏账号" v-model="gameAccount" />
          </div>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>请选择操作系统</p>
        </div>
        <div class="way">
          <p
            v-for="(item, index) in regionList.param"
            :key="index"
            @click="currentClick(regionList, index)"
            :class="current === index ? 'active' : ''"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>填写账号信息</p>
        </div>
        <div class="goods-info">
          <div style="margin-top: 20px">
            <div class="goods-filter-item" v-for="(item, index) in inputArr" :key="index">
              <p>{{ item.name }}:</p>
              <input
                type="text"
                :placeholder="'请填写' + item.name"
                v-model="inputParam[index]"
                @change="inputChange(inputParam[index], item.id)"
              />
            </div>
            <div class="goods-filter-item" v-for="(item, index) in checkArr" :key="index">
              <p>{{ item.name }}：</p>
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(i, checkIndex) in item.param"
                  :key="checkIndex"
                  :label="i"
                  size="large"
                  @change="checkboxChange(item, i)"
                />
              </el-checkbox-group>
            </div>
            <div class="goods-filter-item" v-for="(item, index) in radioArr" :key="index">
              <p>{{ item.name }}:</p>
              <div
                class="goods-condition-right"
                :class="moreDevelop == false ? 'develop' : ''"
                :ref="goodsCondition.set"
              >
                <p
                  v-for="(option, i) in item.param"
                  :key="i"
                  :class="{ 'select-active': item.index === i }"
                  @click="selectClick(option, index, i)"
                >
                  {{ option }}
                </p>
              </div>
              <div class="goods-filter-right-more" @click="moreClick" v-if="moreBtn === true">
                <text>{{ moreText }}</text>
                <img :src="moreImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">提交信息生成鉴定报告</div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CirclePlus } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { ganeParams } from '@/api/goods'
import { useTemplateRefsList } from '@vueuse/core'
import { appraisalInfo, addAppraisal, freeAppraisal } from '@/api/identify'
const route = useRoute()
const router = useRouter()
const store = useStore()
const game = ref({})
const current = ref(null)
const radioArr = ref([])
const checkArr = ref([])
const inputArr = ref([])
const inputParam = ref([])
const goodsCondition = useTemplateRefsList()
const moreImg = ref(require('@/assets/images/icon-down.png'))
const moreText = ref('展开')
const moreDevelop = ref(true)
const moreBtn = ref(false)
let arr = []
const regionList = ref([])
const cost = ref(null)
const gameAccount = ref('')
function getApprInfo() {
  appraisalInfo().then((res) => {
    if (res.code == 200) {
      cost.value = res.data.appraisal_fee
    }
  })
}
function getGaneParams() {
  let type = ''
  if (route.query.type === 'pay') {
    type = '2'
  } else if (route.query.type === 'free') {
    type = '1'
  }
  ganeParams(game.value.id, type).then((res) => {
    if (res.code == 200) {
      regionList.value = res.data[0]
      let list = res.data.slice(1)
      for (let i = 0; i < list.length; i++) {
        if (list[i].display === 0) {
          radioArr.value.push(list[i])
        } else if (list[i].display === 1) {
          checkArr.value.push(list[i])
        } else if (list[i].display === 3) {
          inputArr.value.push(list[i])
        }
      }
      nextTick(() => {
        console.log(goodsCondition.value)
        console.log(radioArr.value)
        for (let i = 0; i < goodsCondition.value.length; i++) {
          for (let g = 0; g < radioArr.value.length; g++) {
            if (goodsCondition.value[i].clientHeight < goodsCondition.value[i].scrollHeight) {
              radioArr.value[g].moreBtn = true
            }
          }
        }
      })
      console.log(radioArr.value)
    }
  })
}
function moreClick() {
  moreDevelop.value = !moreDevelop.value
  if (moreDevelop.value === false) {
    moreImg.value = require('@/assets/images/icon-upout.png')
    moreText.value = '收起'
  } else {
    moreImg.value = require('@/assets/images/icon-down.png')
    moreText.value = '展开'
  }
}
function selectClick(option, index, i) {
  radioArr.value[index].index = i
  let param = {
    id: radioArr.value[index].id,
    value: option
  }
  arr.push(param)
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i]['id'] == arr[j]['id']) {
        arr[i]['value'] = arr[j]['value']
        arr.splice(j, 1)
      }
    }
  }
}
const inputChange = (val, id) => {
  let obj = {
    id: id,
    value: val
  }
  arr.push(obj)
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i]['id'] == arr[j]['id']) {
        arr[i]['value'] = arr[j]['value']
        arr.splice(j, 1)
      }
    }
  }
}
function blurInput(e) {
  console.log(e.target.value)
}
function currentClick(e, index) {
  current.value = index
  let obj = {
    id: e.id,
    value: e.param[index]
  }
  if (arr.length > 0) {
    arr = arr.filter((item) => item.id !== e.id)
  }
  arr.push(obj)
}
function unique(arr) {
  const res = new Map()
  return arr.filter(
    (a) => !res.has(a.id) && res.set(a.id, 1) && !res.has(a.name) && res.set(a.name, 1)
  )
}
function submit() {
  if (!gameAccount.value) {
    ElMessage.error('请填写游戏账号!')
    return
  }
  if (route.query.type === 'pay') {
    let params = {
      game_account: gameAccount.value,
      param: arr,
      appraisal_id: localStorage.getItem('goods_id')
    }
    addAppraisal(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/identResults')
        }, 2000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else if (route.query.type === 'free') {
    let params = {
      game_account: gameAccount.value,
      param: arr,
      game_id: game.value.id
    }
    freeAppraisal(params).then((res) => {
      if (res.code == 200) {
        store.commit('report/reportInfo', res.data)
        setTimeout(() => {
          router.push({ path: '/autInfo', query: { type: 'free' } })
        }, 2000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}
function gopage() {
  router.push({ path: '/allGame', query: { type: route.query.type, cost: cost.value } })
}
onMounted(() => {
  game.value = store.state.sell.game
  getGaneParams()
  getApprInfo()
})
</script>
<style lang="less" scoped>
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-body {
    padding: 0;
  }
}
.sellform-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.breadcrumb {
  margin: 14px 0;
  span:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  img {
    margin: 0 3px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
}
.box {
  .title {
    width: 1200px;
    height: 45px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    img {
      width: 20px;
      height: 15px;
    }
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-left: 5px;
    }
  }
  .content {
    width: 1200px;
    height: 174px;
    background: #ffffff;
    text-align: center;
    border-radius: 0 0 12px 12px;
    padding-top: 25px;
    .current {
      display: flex;
      align-items: center;
      margin-left: 18px;
      img {
        height: 16px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 5px;
      }
    }
    .operate {
      display: flex;
      position: relative;
      margin-left: 28px;
      margin-top: 16px;
      img {
        width: 80px;
        height: 80px;
        position: relative;
      }
      p {
        width: 78px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #d5f2ff;
        border-radius: 8px;
        border: 1px solid #40bdef;
        position: absolute;
        bottom: 0;
        left: 100px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #06a4f9;
        cursor: pointer;
      }
    }
  }
  .params-select {
    width: 1200px;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
    padding: 30px;
    .current {
      display: flex;
      align-items: center;
      //margin-left: 18px;
      img {
        height: 16px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 5px;
      }
    }
    .account-info {
      input {
        width: 300px;
        height: 36px;
        background: #f6f7fb;
        border-radius: 8px;
        margin-left: 28px;
        padding-left: 10px;
      }
      p {
        width: 65px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .account {
        display: flex;
        margin-top: 16px;
        align-items: center;
      }
      .password {
        display: flex;
        margin-top: 24px;
        align-items: center;
      }
    }
    .way {
      display: flex;
      margin-top: 16px;
      p {
        // width: 120px;
        height: 36px;
        background: #f6f7fb;
        border-radius: 8px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 36px;
        margin-left: 10px;
        cursor: pointer;
        padding: 0 5px;
      }
      .active {
        border: 1px solid #40bdef;
        background: #d5f2ff;
        color: #06a4f9;
      }
    }
    .goods-info {
      margin-left: 10px;
      margin-top: 16px;
      .goods-title {
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        input {
          width: 300px;
          height: 36px;
          background: #f6f7fb;
          border-radius: 8px;
          margin-left: 28px;
          padding-left: 10px;
        }
      }
      .goods-cover {
        display: flex;
        margin-top: 24px;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 28px;
        }
        .avatar-uploader {
          //   width: 274px;
          height: 150px;
          border-radius: 8px;
        }
        .avatar-uploader-bg {
          background: #f6f7fb;
        }
        .avatar-uploader .avatar {
          width: 274px;
          height: 150px;
          display: block;
          background: #f6f7fb;
        }
        :deep(.el-upload--picture-card) {
          border: none !important;
          background: #f6f7fb;
          width: 274px;
          display: block;
          text-align: center;
        }
        .avatar-uploader .el-upload:hover {
          border-color: var(--el-color-primary);
        }
        .el-icon {
          display: inline-block;
          p {
            margin-right: 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c9c9c9;
          }
        }
        .el-icon {
          font-size: 32px;
          color: #c3c5c9;
          width: 274px;
          text-align: center;
          margin-top: 40px;
        }
      }
      .goods-describe {
        display: flex;
        margin-top: 24px;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 28px;
        }
        textarea {
          background: #f6f7fb;
          padding-left: 10px;
          padding-top: 7px;
          border-radius: 8px;
          border: none;
        }
      }
      .goods-filter-item {
        display: flex;
        flex-wrap: wrap;
        //height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        input {
          margin-left: 20px;
          width: 300px;
          height: 36px;
          background: #f6f7fb;
          border-radius: 8px;
          padding-left: 10px;
        }
        .goods-condition-game {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          white-space: nowrap;
        }
        .goods-condition-right {
          width: 900px;
          margin: 0 20px;
          // height: 40px;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          p {
            padding: 0 10px;
            //background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .select-active {
            background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
            color: #06a4f9;
          }
          input {
            width: 122px;
            height: 32px;
            background: #f4f4f4;
            border-radius: 4px;
            padding-left: 16px;
          }
          text {
            margin: 0 8px;
          }
          .keywords-input {
            width: 290px;
          }
          .btn {
            width: 72px;
            height: 32px;
            float: right;
            text-align: center;
            margin-left: 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
            border-radius: 5px;
            cursor: pointer;
          }
          .input-box {
            float: right;
          }
        }
        .develop {
          height: auto;
        }
        .goods-filter-right-more {
          display: flex;
          width: 68px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          img {
            width: 8px;
            height: 5px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
.btn {
  width: 304px;
  height: 52px;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  border-radius: 8px;
  text-align: center;
  line-height: 52px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 36px;
  cursor: pointer;
}
</style>
